(function($) {

    $(window).on('load', function () {
        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 2000)

        // Slick Slider (jQuery) - Remove these if not in use 
        $('.testimonials .slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: $('.testimonials .prev'),
            nextArrow: $('.testimonials .next')
        });
    })

    var resizeTimer;
    $(window).bind('resize load', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            // Responsive slider for blocks section
            $('.featured-in .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000, 
                arrows: false,
                dots: true,
                responsive: [ 
                    {
                        breakpoint: 992,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ]
            });

            $('.blocks .slider').not('.slick-initialized').slick({
                infinite: true,
                slidesToScroll: 1,
                mobileFirst: true,
                speed: 600,
                autoplay: true,
                autoplaySpeed: 3000,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: 'unslick'
                    }
                ]
            });
        }, 500)
    })

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]', function (event) {
        event.preventDefault()
        var target = $(this).attr('href')

        if ($(target).length) {
            $('html, body').animate({
                scrollTop: $(target).offset().top - 80
            }, 1500)
        }
    });

    // Form Validations & gtm event firing
    // $("#claim-your-free-financial-health-check, #ebook-form").each(function () {
    //     $(this).validate({
    //         submitHandler: function (form) {
    //             $(this).find(".form-messages").html('Thank you for your information!');
    //             $(this).find(".form-messages").removeClass('error').addClass('success');
    //             var response = grecaptcha.getResponse();
    //             if (response != '') {
    //                 form.submit();
    //             } else {
    //                 $(this).find('.form-messages').html('Please prove that you are not a Robot');
    //                 $(this).find(".form-messages").removeClass('success').addClass('error');
    //             }
    //         },
    //         invalidHandler: function (event, validator) {
    //             // 'this' refers to the form
    //             var errors = validator.numberOfInvalids();
    //             if (errors) {
    //             var message =
    //                 errors == 1
    //                 ? "You missed 1 field. It has been highlighted"
    //                 : "You missed " + errors + " fields. They have been highlighted";
    //             $(this).find(".form-messages").html(message);
    //             $(this).find(".form-messages").removeClass('success').addClass('error');
    //             }
    //         },
    //     });
    // });

})( jQuery );

// Get Current Month Name
function getCurrentMonth() {
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now       = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var output = document.getElementsByClassName('output');

    $( ".output" ).html( thisMonth);
}
getCurrentMonth();